<template>
  <div class="help-main mt-5">
    <b-row>
      <b-col cols="3" v-for="(template) in templates">
        <b-card
            :title="'Шаблон ' + template"
            :img-src="require('@/assets/img/'+template+'.jpg')"
            img-top
            img-height="200"
            tag="article"
            class="mb-2"
        >
        </b-card>
      </b-col>
    </b-row>
<!--    <b-card title="Шаблони">-->
<!--      <div>-->
<!--        <div class="d-flex">-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/1.jpg')">-->
<!--            <p>Шаблон №1</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/2.jpg')">-->
<!--            <p>Шаблон №2</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/3.jpg')">-->
<!--            <p>Шаблон №3</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/4.jpg')">-->
<!--            <p>Шаблон №4</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="d-flex">-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/5.jpg')">-->
<!--            <p>Шаблон №5</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/6.jpg')">-->
<!--            <p>Шаблон №6</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/7.jpg')">-->
<!--            <p>Шаблон №7</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img :src="require('@/assets/img/8.jpg')">-->
<!--            <p>Шаблон №8</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-card>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      templates: [1,2,3,4,5,6,7,8]
    }
  },
  methods: {
    addTag(v) {
      this.tag = v;
    }
  },
  name: "help"
}
</script>

<style scoped>
@import '../assets/help.css';
</style>