<template>
  <div class="main-panel mt-5">
    <product-form :product="product"
                  :materials="materials"
                  :edges="edges"
                  :screeds="screeds"
                  @on-submit="updateProduct" />
  </div>
</template>

<script>
import {productService} from "../../services/productService";
import productForm from "./Form.vue"

export default {
  components : {
    productForm
  },
  data() {
    return {
      loading: false,
      product : {},
      materials : [],
      edges : [],
      screeds : [],
      colors: [
        '#1abc9c', '#c700fa', '#2746ac', '#9b59b6', '#34495e', '#e74c3c',
        '#e67e22', '#f39c12', '#7f8c8d', '#f368e0', '#341f97', '#6D214F',
        '#BDC581', '#fdaf00', '#795548', '#c0392b', '#0984e3', '#55efc4',
        '#fdcb6e', '#e84393', '#2d3436',
      ]
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadProductData()
    }
  },
  methods: {
    updateProduct(data) {
      productService.updateProduct(this.$route.params.id, data).then(response => {
        this.$toast.success("Успішно оновлено");
      }).then(r => {
        this.loading = false;
      }).catch(e => {
        this.loading = false;
        this.$toast.error("Щось пішло не так =(");
      })
    },
    loadProductData() {
      this.loading = true;
      productService.getProduct(this.$route.params.id).then((response) => {
        if (response) {
          this.product = response.product
          this.materials = response.materials
          this.edges = response.edges.map((val, i) => {
            val['color'] = this.colors[i];
            return val
          })
          this.screeds = response.screeds.filter((v, i, self) => i == self.indexOf(v))
          this.loading = false
        }
      });
    }
  },
  name: "product-list"
}
</script>

<style scoped>
</style>