<template>
  <div class="material_table_wrapper">
    <table class="material_table table">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            У
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            Матеріал
            <button class="button button__medium" data-toggle="modal"
                    data-target="#searchModal" @click="search('materials')">
              Додати
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="materials">
      <tr v-for="(material, index) in materials" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <div class="table__checkbox">
            <input :id="'m_'+index" class="checkbox-input" type="radio" v-model="materialDefault"
                   :value="material.ref" @change="change(index)">
            <label :for="'m_'+index"></label>
          </div>
        </td>
        <td>{{ material.full_name }}</td>
        <td>
          <div class="table__buttons">
            <button class="button button__icon" @click="replace(index)" data-toggle="modal" data-target="#searchModal">
              <img :src="require('@/assets/img/copy.png')">
            </button>
            <button class="button button__icon" @click="remove(index)">
              <img :src="require('@/assets/img/trash-can.png')">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "MaterialsTable",
  props : [
    'toSearch'
  ],
  computed: {
    materialDefault: {
      get() {
        return this.$store.getters['data/defaultMaterial'] ? this.$store.getters['data/defaultMaterial'].ref : '';
      },
      set(value) {
        this.$store.commit('data/SET_DEFAULT_MATERIALS', value)
      }
    },
    materials() {
      return this.$store.state.data.materials;
    }
  },
  methods: {
    search(v) {
      app.toSearch = v;
      this.toSearch = v;
    },
    change(i) {
      this.materialDefault = this.materials[i];
    },
    replace(i) {
      this.search('materials');
      app.replace = this.materials[i].ref;
    },
    remove(i) {
      if (!confirm('Цей матеріал буде видалено! Видалити?')) return;

      let ref = this.materials[i].ref;
      app.details.forEach((el) => {
        if (el.material === ref) el.material = null;
      })

      this.$store.commit('data/REMOVE_MATERIAL', i);

      if (this.materialDefault === i || this.materials.length === 0) {
        this.$store.commit('data/SET_DEFAULT_MATERIALS', null)
      }
    }
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>