<template>
  <div class="material_table_wrapper">
    <table class="material_table table">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            У
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            Матеріал
            <button class="button button__medium" data-toggle="modal"
                    data-target="#searchModalProduct" @click="search('materials')">
              Додати
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="materials">
      <tr v-for="(material, index) in materials" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <div class="table__checkbox">
            <input :id="'m_'+index" class="checkbox-input" type="radio"
                   @change="change(index)"
                   :value="material.id" v-model="materialsDefault.id">
            <label :for="'m_'+index"></label>
          </div>
        </td>
        <td>{{ material.full_name }}</td>
        <td>
          <div class="table__buttons">
            <!--            <button class="button button__icon" @click="replace(index)" data-toggle="modal" data-target="#searchModal">-->
            <!--              <img :src="require('@/assets/img/copy.png')">-->
            <!--            </button>-->
            <button class="button button__icon" @click="remove(index)">
              <img :src="require('@/assets/img/trash-can.png')">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "MaterialsTable",
  props: [
    'toSearch',
    'materials',
    'materialsDefault',
  ],
  methods: {
    change(i) {
      this.$root.$emit('setMaterialDefault', this.materials[i])
    },
    search(v) {
      this.$emit('interface', v);
      this.toSearch = v;
    },
    remove(i) {
      this.$root.$emit('on-remove', 'materials', i);
    }
  },
}
</script>