<template>
  <div class="edge_table_wrapper">
    <table class="edge_table table table-bordered">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            У
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            Материал
            <button class="button button__medium" data-toggle="modal"
                    data-target="#searchModal" @click="search('edges')">
              Добавить
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(edge, index) in edges" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <div class="table__checkbox">
            <input :id="'e_'+index" class="checkbox-input" type="radio" v-model="edgeDefault"
                   :value="edge.ref" @change="change(index)">
            <label :for="'e_'+index"></label>
          </div>
        </td>
        <td class="text-color" :style="{color:edge.color}" :data-ref="edge.ref">{{ edge.full_name }}</td>
        <td>
          <div class="table__buttons">

            <button class="button button__icon" @click="replace(index)" data-toggle="modal" data-target="#searchModal">
              <img :src="require('@/assets/img/copy.png')">
            </button>
            <button class="button button__icon" @click="remove(index)">
              <img :src="require('@/assets/img/trash-can.png')">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EdgeTable",
  props : [
    'toSearch'
  ],
  computed: {
    edgeDefault :{
      get() {
        let def = this.$store.getters['data/defaultEdge']
        return def ? def.ref : '';
      },
      set(value) {
        this.$store.commit('data/SET_DEFAULT_EDGES', value);
      }
    },
    edges() {
      return this.$store.state.data.edges;
    },
  },
  methods: {
    search(v) {
      app.toSearch = v;
      this.toSearch = v;
    },
    change(i) {
      this.edgeDefault = this.edges[i];
    },
    replace(i) {
      this.search('edges');
      app.replace = this.edges[i].ref;
    },
    remove(i) {
      if (!confirm('Данный материал кромки будет удален с деталировки! Удалить? ')) return;
      let ref = this.edges[i].ref;
      app.details.forEach((el) => {
        if (el.edge_t === ref) el.edge_t = null;
        if (el.edge_b === ref) el.edge_b = null;
        if (el.edge_l === ref) el.edge_l = null;
        if (el.edge_r === ref) el.edge_r = null;
        if (el.edge_template === ref) el.edge_template = null;
      })

      this.edges.splice(i, 1);
      this.$store.commit('data/SET_EDGES', this.edges);
      if (this.edgeDefault == i || this.edges.length == 0) {
        app.edgeDefault = 0
        this.$store.commit('data/SET_DEFAULT_EDGES', null);
      }
    }
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>