<template>
  <div class="edge_table_wrapper">
    <table class="edge_table table table-bordered">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            У
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            Материал
            <button class="button button__medium" data-toggle="modal"
                    data-target="#searchModalProduct" @click="search('edges')">
              Добавить
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(edge, index) in edges" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <div class="table__checkbox">
            <input :id="'e_'+index" class="checkbox-input" type="radio" :checked="edgeDefault.id === edge.id"
                   @change="change(index)"
                   :value="edge.id">
            <label :for="'e_'+index"></label>
          </div>
        </td>
        <td class="text-color" :style="{color:edge.color}" :data-ref="edge.ref">{{ edge.full_name }}</td>
        <td>
          <div class="table__buttons">

<!--            <button class="button button__icon" @click="replace(index)" data-toggle="modal" data-target="#searchModal">-->
<!--              <img :src="require('@/assets/img/copy.png')">-->
<!--            </button>-->
            <button class="button button__icon" @click="remove(index)">
              <img :src="require('@/assets/img/trash-can.png')">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EdgeTable",
  props: [
    'toSearch',
    'edges',
    'edgeDefault',
  ],

  methods: {
    change(i) {
      this.$root.$emit('setEdgeDefault', this.edges[i])
    },
    search(v) {
      this.$emit('interface', v);
      this.toSearch = v;
    },
    remove(i) {
      this.$root.$emit('on-remove', 'edges', i);
    }
  },
}
</script>