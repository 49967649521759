<template>
  <div>
    <b-modal
        id="modal-order"
        ref="modal"
        title="Вкажіть ще дещо"
        v-model="visible"
        header-close-content=""
        @hidden="resetModal"
        @ok="handleOk"
    >
      <h3>
        Вкажіть бажану кількість виробів
      </h3>
      <b-form>
        <template v-for="product in products">
          <label class="mr-sm-2 mt-2" :for="'product_' + product.id">
            {{ product.name }}
          </label>
          <b-form-input
              type="number"
              min="1"
              :id="'product_' + product.id"
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model.number="product.count"
          ></b-form-input>
        </template>
      </b-form>

      <h6 class="mt-2">
        Вкажіть параметри замовлення
      </h6>

      <form ref="form">
        <b-form-checkbox v-model="order.customer_material" size="lg">
          Матеріал Клієнта
        </b-form-checkbox>
        <b-form-checkbox v-model="order.customer_edge" size="lg">
          Кромка Клієнта
        </b-form-checkbox>
        <b-form-checkbox v-if="orderData?.edge_size || false" v-model="order.edge_size" size="lg">
          Розмір з Кромкою
        </b-form-checkbox>
        <b-form-checkbox v-model="order.glue" size="lg">
          Оклеювання клеєм PUR
        </b-form-checkbox>
      </form>
    </b-modal>
  </div>
</template>

<script>


export default {
  props :[
      'visible',
      'products',
      'orderData',
  ],
  data() {
    return {
      order : {
        customer_material : this.orderData?.customer_material || false,
        customer_edge : this.orderData?.customer_edge || false,
        edge_size : this.orderData?.edge_size || false,
        glue : this.orderData?.glue || false,
      }
    }
  },
  methods: {
    resetModal() {
     this.$emit('on-close')
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$emit('on-submit', this.order, this.products);
    }
  }
}
</script>