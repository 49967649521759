<template>
  <div class="main-panel mt-5">
    <product-form @on-submit="createProduct" />
  </div>
</template>

<script>
import {productService} from "../../services/productService";
import productForm from "./Form.vue"

export default {
  components : {
    productForm
  },
  data() {
    return {
      id:null
    }
  },
  methods: {
    createProduct(data) {
      if(this.id !== null) {
        productService.updateProduct(this.id, data).then(response => {
          this.$toast.success("Успішно оновлено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        })
      } else {
        productService.createProduct(data).then(response => {
          this.id = response.product.id;
          this.$toast.success("Успішно збережено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        })
      }

    }
  },
  name: "product-create"
}
</script>

<style scoped>
</style>