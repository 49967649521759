<template>
  <div class="material_table_wrapper">
    <table class="material_table table">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            Назва
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            <span></span>
            <button class="button button__medium" @click="add">
              Додати
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="detailsName">
      <tr v-for="(detail, index) in detailsName" :key="index">
        <td>{{ index + 1 }}</td>
        <td colspan="2">
          <div class="table__text">
            <input :id="'dt_'+index"
                   placeholder="Назва"
                   v-model="detail.name"
                   @change="processSimilarName(detail, index)"
                   type="text">
            <label :for="'dt_'+index"></label>
          </div>
        </td>
        <td>
          <div class="table__buttons">
            <b-button v-if="validaToSave(detail)"
                      @click="save(detail)"
                      variant="success"><i class="far fa-save"></i></b-button>

            <b-button variant="danger" @click="remove(index)">
              <i class="far fa-trash-alt"></i>
            </b-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {productService} from "../../services/productService";

export default {
  name: "DetailsNameTable",
  props: ['detailsName'],
  methods: {
    add() {
      this.detailsName.push({id: null, name: ''})
    },
    remove(i) {
      this.detailsName.splice(i, 1);
    },
    processSimilarName(currentDetail, key) {

      let oldList = Object.keys(this.detailsName).filter(item => item != key);
      let finded = oldList.filter(detail => this.detailsName[detail].name == currentDetail.name);

      if(finded.length > 0) {

          this.$store.commit('data/SET_DETAILS', this.$store.state.data.details.map((item) => {
            if(this.detailsName.filter(detail => detail.name === item.detail_name && item.detail_name.length > 0).length === 0) {
              item.detail_name = currentDetail.name;
            }
            return item;
          }));
          this.detailsName.splice(key, 1);
      }
    },
    save(detail) {
      if (this.loading) return;
      let details = this.$store.state.data.details.filter((item) => item.name === detail.name);
      this.loading = true;
      let data = {
        "customer": this.$store.state.auth.user.client.ref,
        "name": detail.name,
        "customer_material": this.$store.state.data.order?.customer_material || false,
        "customer_edge": this.$store.state.data.order?.customer_edge || false,
        "glue": this.$store.state.data.order?.glue || false,
        "edge_size": this.$store.state.data.order?.edge_size || false,
        "items": details,
      };
      this.$toast.success("Вже робимо");
      if(detail.id !== null && detail.id !== undefined) {
        productService.updateProduct(detail.id, data).then(response => {
          this.$toast.success("Успішно оновлено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        })
      } else {
        productService.createProduct(data).then(response => {
          detail.id = response.product.id;
          this.$toast.success("Успішно збережено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        });
      }

    },
    validaToSave(detail) {
      let details = this.$store.state.data.details.filter((item) => item.name === detail.name);
      return details.length > 0 && detail.name.length > 0;
    }
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>

<style scoped>
.table__buttons {
  gap: 10px
}
</style>