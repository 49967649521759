import axios from 'axios'
import appconfig from "../app/config";

export const productService = {
    getListProducts,
    getProduct,
    createProduct,
    updateProduct,
    deleteProduct,
}

function getListProducts(params = '') {
    return axios.get(appconfig.Point + 'api/constructor/v1/product' + params)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}

function getProduct(id) {
    return axios.get(appconfig.Point + 'api/constructor/v1/product/view/' + id)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}

function createProduct(data) {
    return axios.post(appconfig.Point + 'api/constructor/v1/product/create', data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}

function updateProduct(id, data) {
    return axios.post(appconfig.Point + 'api/constructor/v1/product/update/' + id, data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}

function deleteProduct(id) {
    return axios.get(appconfig.Point + 'api/constructor/v1/product/delete/' + id)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}